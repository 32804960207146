/* eslint-disable global-require */
const containers = [
  {
    id: 1,
    ref: "ftl_53",
    container_name: "FTL 53",
    lenght: 636,
    width: 96,
    height: 96,
    weight: 5000,
    volume: 6422328.0,
    img: require("../assets/images/containers/ftl53.png"),
  },
  {
    id: 2,
    ref: "cargo_van",
    container_name: "CARGO VAN",
    lenght: 128,
    width: 50,
    height: 50,
    weight: 2000,
    volume: 405600.0,
    img: require("../assets/images/containers/cargoVan.png"),
  },
  {
    id: 3,
    ref: "sprinter_van",
    container_name: "SPRINTER VAN",
    lenght: 160,
    width: 55,
    height: 65,
    weight: 3000,
    volume: 624000.0,
    img: require("../assets/images/containers/sprinterVan.png"),
  },
  {
    id: 4,
    ref: "large_straight",
    container_name: "LARGE STRAIGHT",
    lenght: 312,
    width: 96,
    height: 96,
    weight: 9800,
    volume: 2875392.0,
    img: require("../assets/images/containers/largeStraight.png"),
  },
  {
    id: 5,
    ref: "flat_bed",
    container_name: "FLAT BED",
    lenght: 576,
    width: 102,
    height: 102,
    weight: 100000,
    volume: 5992704.0,
    img: require("../assets/images/containers/flatbed.png"),
  },
  {
    id: 6,
    ref: "step_deck",
    container_name: "STEP DECK",
    lenght: 636,
    width: 102,
    height: 120,
    weight: 100000,
    volume: 7784640.0,
    img: require("../assets/images/containers/stepdeck-shot20.png"),
  },
  {
    id: 7,
    ref: "hot_shot_20",
    container_name: "HOT SHOT 20",
    lenght: 240,
    width: 102,
    height: 120,
    weight: 100000,
    volume: 2937600.0,
    img: require("../assets/images/containers/stepdeck-shot20.png"),
  },
  {
    id: 8,
    ref: "hot_shot_30",
    container_name: "HOT SHOT 30",
    lenght: 360,
    width: 102,
    height: 120,
    weight: 100000,
    volume: 4406400.0,
    img: require("../assets/images/containers/shot30-shot40.png"),
  },
  {
    id: 9,
    ref: "hot_shot_40",
    container_name: "HOT SHOT 40",
    lenght: 480,
    width: 102,
    height: 120,
    weight: 100000,
    volume: 5875200.0,
    img: require("../assets/images/containers/shot30-shot40.png"),
  },
  {
    id: 10,
    ref: "ltl_dry_van_53",
    container_name: "LTL DRY VAN 53",
    lenght: 636,
    width: 96,
    height: 96,
    weight: 2500,
    volume: 5861376.0,
    img: require("../assets/images/containers/ltlDry.png"),
  },
  {
    id: 11,
    ref: "small_straight",
    container_name: "SMALL STRAIGHT",
    lenght: 180,
    width: 88,
    height: 88,
    weight: 4500,
    volume: 1156000.0,
    img: require("../assets/images/containers/smallStraight.png"),
  },
  {
    id: 12,
    ref: "Container 40' STD Chassis",
    container_name: "Container 40' STD Chassis",
    lenght: 480,
    width: 96,
    height: 102,
    weight: 58951,
    volume: Infinity,
    img: require("../assets/images/containers/empty.png"),
  },
  {
    id: 13,
    ref: "Container 40' HC chassis",
    container_name: "Container 40' HC chassis",
    lenght: 480,
    width: 96,
    height: 107,
    weight: 58422,
    volume: Infinity,
    img: require("../assets/images/containers/empty.png"),
  },
  {
    id: 14,
    ref: "Container 20' STD chassis",
    container_name: "Container 20' STD chassis",
    lenght: 240,
    width: 96,
    height: 102,
    weight: 47840,
    volume: Infinity,
    img: require("../assets/images/containers/empty.png"),
  },
  {
    id: 15,
    ref: "Container 45' STD Chassis/HC chassis",
    container_name: "Container 45' STD Chassis/HC chassis",
    lenght: 540,
    width: 96,
    height: 102,
    weight: 58951,
    volume: Infinity,
    img: require("../assets/images/containers/empty.png"),
  },
  {
    id: 16,
    ref: "RGN",
    container_name: "RGN",
    lenght: 360,
    width: 102,
    height: 36,
    weight: Infinity,
    volume: Infinity,
    img: require("../assets/images/containers/empty.png"),
  },
];
export default containers;
